
// TYPO3 forms
.frame-type-form_formframework {
    form {
        margin-bottom: 3rem;

        option {
            font-weight: $font-weight-normal;
        }

        .form-control {
            padding: $input-btn-padding-y $input-btn-padding-x;
        }

        .form-element-radio {
            legend {
                font-size: 1rem;
                font-weight: $font-weight-bold;
            }
        }
    }
}


// mdb, fastbooking
.select-dropdown-container {
    .select-option {
        font-weight: $font-weight-normal;

        &.selected {
            background-color: transparent;

            &.active {
                background-color: $gray-lighter;
            }
        }

        &:hover:not(.disabled) {
            background-color: $gray-lighter;
        }
    }
}