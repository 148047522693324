footer {
    margin-top: 1.5rem;

    a,
    a.nav-link {
        color: $body-color;
    }

    h6 {
        font-size: 1.25rem;
        text-transform: none;
    }

    .footer-block {
        border-top: 1px solid $gray-light;
        padding-top: 3rem;

        .social-newsletter {
            margin-bottom: 3rem;
        }

        /*
        .footer-form-nl {
            .input-group {
                >button:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
                    margin-left: 0.875rem;
                    border-top-left-radius: $border-radius;
                    border-bottom-left-radius: $border-radius;
                }
            }
        }
        */

        .footer-navigation {
            margin-bottom: 1.5rem;

            ul.nav {
                margin-bottom: 1rem;

                .nav-link {
                    padding: 0.375rem 1rem 0.375rem 0;
                }
            }
        }

        .partner-links {
            margin-bottom: 2.5rem;

            ul {
                list-style: none;
                padding-left: 0;

                li {
                    display: inline;

                    a {
                        margin: 0 0.5rem;
                    }

                    &::after {
                        content: '|';
                    }

                    &:first-of-type {
                        a {
                            margin-left: 0;
                        }
                    }

                    &:last-of-type {
                        &::after {
                            content: '';
                        }
                    }
                }
            }
        }

        .footer-logos {
            margin-bottom: 1rem;

            .logo-col-left {
                margin-bottom: 2rem;
            }

            .logo-col-right {
                a {
                    display: block;
                    margin: 0.5rem 1rem 2rem 0;
                }
            }

            @include media-breakpoint-up(md) {
                margin-bottom: 2.25rem;

                .logo-col-left {
                    margin-bottom: 0;
                }

                .logo-col-right {
                    margin-bottom: 0;
                    text-align: right;

                    a {
                        display: inline;
                        margin: 0 0 0 2rem;
                    }
                }
            }
        }

        &.footer-block--bottom {
            padding-top: 1rem;
        }

        .footer-metanav {
            margin-bottom: 1rem;

            .nav {
                --#{$prefix}nav-link-padding-x: 0.5rem;
                --#{$prefix}nav-link-padding-y: 0.25rem;

                .nav-item:first-of-type {
                    .nav-link {
                        padding-left: 0;
                    }
                }

                .nav-link-uccmp {
                    cursor: pointer;
                }
            }
        }

        .copyright {
            @include media-breakpoint-up(md) {
                text-align: right;
            }
        }
    }
}
