.btn {
    &.btn-primary,
    &.btn-secondary {
        --#{$prefix}btn-color: #fff;
    }

    &.btn-outline-primary,
    &.btn-outline-secondary {
        --#{$prefix}btn-hover-color: #fff;
    }
}

.btn:hover {
    &.btn-primary,
    &.btn-secondary {
        --#{$prefix}btn-hover-color: #fff;
    }
}

.destination-links {
    margin-bottom: 3rem;

    .btn {
        margin-right: 0.5rem;
    }
}
