/* roboto-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/roboto-v30-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-500 - latin */
@font-face {
    font-display: swap;
    font-family: Roboto;
    font-style: bold;
    font-weight: 500;
    src: url('../fonts/roboto-v30-latin-500.woff2') format('woff2');
}
