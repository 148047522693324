.pagination {
    .page-link {
        border-radius: $pagination-border-radius;
        line-height: 1;
        margin-right: 0.5rem;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.page-link-dots {
            border: none;
            line-height: 1.5;

            &:hover,
            &:active,
            &:focus {
                background-color: transparent;
                border: none;
                box-shadow: none;
            }
        }
    }
}