.tx-knfastbooking {
    position: relative;
    padding: 1.5rem;
    background-color: $color-booking;
    border-radius: $border-radius;

    .nav-tabs {
        --bs-nav-tabs-border-width: 0;
        --bs-nav-tabs-link-active-color: #{$primary};
        --bs-nav-tabs-link-active-bg: transparent;

        @include media-breakpoint-down(md) {
            overflow-x: scroll;
            display: flex;
            flex-flow: row nowrap;
        }

        .nav-link {
            color: $gray-darker;
            white-space: nowrap;

            &.active {
                color: var(--bs-nav-tabs-link-active-color);
                font-weight: $font-weight-bold;
                border-bottom: 1px solid $primary;
                margin-bottom: 0.75rem;
            }
        }
    }

    .input-group-text {
        padding: 0.375rem 0.25rem;
        background-color: #fff;

        i {
            display: none;
        }

        @include media-breakpoint-up(sm) {
            padding: 0.375rem 0.75rem;

            i {
                display: flex;
            }
        }
    }

    .input-group {
        .input-group-text {
            border-right: none;
        }

        .form-floating > input,
        .form-floating .select-wrapper .form-outline > input {
            border-left-width: 0;
        }
    }

    .btn {
        &.btn-primary {
            padding: 1rem 1.5rem;
        }
    }

    .form-floating {
        // mdb select
        &.mdb-select {
            .select-wrapper {
                .select-arrow {
                    display: none;
                }

                .select-clear-btn {
                    top: 17px;
                    right: 30px;
                }

                .select-input {
                    padding-top: 1.625rem;
                    padding-bottom: 0.5rem;
                    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23666%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e');
                    background-repeat: no-repeat;
                    background-position: right 0.75rem center;
                    background-size: 16px 12px;
                }

                .select-input.form-control[readonly]:not([disabled]) {
                    background-color: $white;

                    &::placeholder {
                        color: $body-color;
                    }
                }

                .form-outline .form-control ~ .form-notch {
                    div {
                        border-color: $gray-dark;
                    }

                    .form-notch-leading {
                        border-left: none;
                        border-radius: 0;
                    }
                }

                .form-outline .form-control~.form-label.select-fake-value.active,
                .form-outline .form-control:focus~.form-label.select-fake-value.active,
                .form-outline .form-control.active~.form-label.select-fake-value.active {
                    display: none;
                }
            }

            > label {
                transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
                opacity: 0.65;
            }
        }

        &.dropdown-travellers {
            label {
                font-size: 0.875rem;
            }

            .number-spinner {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                align-items: stretch;
                width: 100%;

                .btn-number-spinner-dec {
                    border-radius: 50%;
                    padding: 0 0.81rem;
                }

                .btn-number-spinner-inc {
                    border-radius: 50%;
                    padding: 0 0.66rem;
                }

                .input-number-spinner {
                    flex: 1 1 auto;
                    width: 1%;
                    min-width: 0;
                    margin: 0 0.5rem;
                    border: 0;
                    background-color: $color-booking;
                    text-align: center;
                    box-shadow: none;
                }
            }

            .form-select-age {
                display: inline-block;
                width: auto;
                border-left: 1px solid $gray-light;
            }
        }
    }
}

.container-fastbooking {
    .tx-knfastbooking {
        margin-top: 2rem;
        z-index: 6;

        @include media-breakpoint-up(xl) {
            margin-top: -5rem;
        }

        @include media-breakpoint-up(xxl) {
            margin-top: -10rem;
        }
    }
}

// for view when data-mdb-inline="false"
.datepicker-modal-container {
    .datepicker-title {
        display: none;
    }

    .datepicker-footer {
        .datepicker-clear-btn,
        .datepicker-cancel-btn {
            display: none;
        }

        .datepicker-ok-btn,
        .datepicker-ok-btn:hover,
        .datepicker-ok-btn:focus {
            border-radius: 4px;
            color: #fff;
            background-color: $black;
        }
    }
}
