.accordion-item {
    border-bottom: 1px solid $gray-dark;

    &:last-of-type {
        border-bottom: none;
    }

    .accordion-button {
        font-size: 1.5rem;
        font-weight: $font-weight-normal;
    }
}