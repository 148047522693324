.header-media {
    position: relative;

    img {
        position: relative;
        width: 100%;
        height: auto;
    }

    .text-overlay {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 1rem;
        z-index: 4;

        .title,
        .text-description {
            font-size: 1.5rem;
            line-height: 1.25;
            padding: 0.75rem;
            display: inline-block;

            i {
                font-size: 1rem;
                display: none;
            }
        }

        .title {
            margin: 0 1rem;
            background-color: #fff;
            color: $primary;
        }

        .text-description {
            margin: 0 1rem 0 2rem;
            background-color: $primary;
            color: #fff;
            transition: scale 0.3s linear;
        }

        @media (width >= 400px) {
            .title,
            .text-description {
                font-size: 2rem;
                padding: 1rem 1.5rem;

                i {
                    font-size: 1.25rem;
                }
            }
        }

        @include media-breakpoint-up(sm) {
            bottom: 2rem;

            .title,
            .text-description {
                font-size: 1.5rem;

                i {
                    font-size: 1rem;
                }
            }
        }

        @include media-breakpoint-up(md) {
            top: 8rem;
            bottom: unset;
        }

        @include media-breakpoint-up(lg) {
            top: 9rem;

            .title,
            .text-description {
                font-size: 2.5rem;

                i {
                    font-size: 1.5rem;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            top: 11rem;

            .title,
            .text-description {
                font-size: 3rem;
            }

            .title {
                margin-left: 3rem;
            }

            .text-description {
                margin-left: 5rem;

                i {
                    font-size: 2rem;
                }
            }
        }

        @media (width >= 1500px) {
            top: 14rem;
        }
    }

    a {
        .text-overlay {
            &:hover {
                .text-description {
                    scale: 1.02;
                }
            }

            .text-description {
                i {
                    display: inline;
                }
            }
        }
    }

    picture {
        position: relative;
        display: block;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(rgb(0 0 0 / 40%),rgb(0 0 0 / 0%),rgb(0 0 0 / 0%));
            z-index: 1;
        }
    }
}

/*
.splide {
  .splide__list {
    list-style: none;
    padding-left: 0;
  }
  .splide__slide {
    a {
      display: block;
      position: relative;
    }

    picture {
      position: relative;
      display: block;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0),rgba(0,0,0,0));
        z-index: 1;
      }
    }
  }
}
*/
