.mb-small,
.frame-mb-small {
    margin-bottom: 2rem;
}

.mb-medium,
.frame-mb-medium {
    margin-bottom: 4.5rem;
}

.mb-large,
.frame-mb-large {
    margin-bottom: 6rem;
}

.frame-type-text {
    &.frame-layout-1 {
        --bs-link-color-rgb: 255, 255, 255;

        color: #fff;
        padding: 1.25rem;
        background-color: $secondary-dark;
        border-radius: $border-radius;
        margin-bottom: 2rem;

        p:last-of-type {
            margin-bottom: 0;
        }
    }
}

.frame-type-image {
    .ce-row {
        @extend .row;
    }

    .image-embed-item {
        max-width: 100%;
        height: auto;
    }

    .ce-gallery {
        a {
            &:focus-visible {
                display: block;
                border-radius: 8px;
                outline: 0;
                box-shadow: $card-focus-box-shadow;
            }

        }
    }

    .ce-gallery[data-ce-columns='4'] {
        .ce-column {
            @extend .col-12;
            @extend .col-sm-6;
            @extend .col-lg-3;
        }
    }

    .ce-gallery[data-ce-columns='3'] {
        .ce-column {
            @extend .col-12;
            @extend .col-sm-6;
            @extend .col-lg-4;
        }
    }

    .ce-gallery[data-ce-columns='2'] {
        .ce-column {
            @extend .col-12;
            @extend .col-sm-6;
        }
    }

    .card {
        &.card-image {
            margin-bottom: 3rem;

            .image-embed-item {
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
            }

            .card-body {
                padding: 1rem 0;

                p {
                    margin-bottom: 0.375rem;
                }

                h5 {
                    font-size: 1.5rem;
                }
            }

            &.card-image-overlay {
                .image-embed-item {
                    border-bottom-left-radius: $border-radius;
                    border-bottom-right-radius: $border-radius;
                }

                picture {
                    position: relative;
                    display: block;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(rgb(0 0 0 / 0%),rgb(0 0 0 / 36%));
                        z-index: 1;
                        border-radius: $border-radius;
                    }
                }

                .card-body {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    padding: 0;

                    .content-overlay {
                        padding: 0 1.5rem 1.5rem;
                        color: #fff;
                    }

                    h5 {
                        font-size: 1.75rem;
                    }

                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.frame-type-textmedia {
    .image-embed-item {
        max-width: 100%;
        height: auto;
    }

    .video-embed {
        @extend .ratio;
        @extend .ratio-16x9;
    }

    .ce-textpic {
        @extend .row;

        margin-bottom: 4.5rem;

        .ce-gallery,
        .ce-bodytext {
            @extend .col-12;
        }

        .ce-bodytext {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &.ce-intext {
            .image-embed-item {
                border-radius: $border-radius;
            }

            &.ce-left {
                .ce-gallery {
                    @extend .col-md-5;
                }

                .ce-bodytext {
                    @extend .offset-md-1;
                    @extend .col-md-6;
                    @extend .col-lg-5;
                }
            }

            &.ce-right {
                .ce-gallery {
                    @extend .col-md-5;
                    @extend .offset-md-1;
                    @extend .order-md-2;
                }

                .ce-bodytext {
                    @extend .offset-lg-2;
                    @extend .col-md-6;
                    @extend .col-lg-4;
                    @extend .order-md-1;
                }
            }
        }
    }
}

.frame-type-menu_subpages,
.frame-type-menu_pages,
.frame-type-menu_section {
    margin-bottom: 2rem;

    &.frame-layout-0 {
        ul {
            list-style-type: none;
            padding-left: 0;

            li {
                a {
                    position: relative;
                    display: block;
                    color: $body-color;
                    padding: 0.5rem 0;
                    margin: 0.5rem 0;
                    border-bottom: 1px solid $gray-light;

                    &::after {
                        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
                        font-family: iconkuoni;
                        content: '\e911';
                        position: absolute;
                        right: 0;
                        font-size: 0.875rem;
                    }
                }
            }
        }
    }

    &.frame-layout-1 {
        ul {
            list-style-type: none;
            padding-left: 0;

            li {
                display: inline-block;

                a {
                    @extend .btn;
                    @extend .btn-outline-secondary;

                    margin: 0 0.5rem 0.75rem 0;
                }
            }
        }
    }
}

.frame-type-menu_section {
    &.frame-layout-0 {
        ul {
            li {
                a {
                    &::after {
                        content: '\e912';
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}

.frame-type-table {
    .ce-table {
        @extend .table;

        width: auto;

        &.ce-table-striped {
            @extend .table-striped;
        }

        &.ce-table-bordered {
            @extend .table-bordered;
        }
    }
}

.grid-masonry {
    .grid-item {
        position: relative;
        overflow: hidden;

        figure {
            overflow: hidden;
            margin: 0;
            border-radius: $border-radius;

            img {
                transition: transform 0.5s ease-in-out;
            }

            &::after {
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                content: '';
                display: block;
                opacity: 1;
                transition: opacity 0.5s;
                background: radial-gradient(circle,rgb(0 0 0 / 30%),rgb(0 0 0 / 20%) 80%,transparent);
            }
        }

        .content {
            position: absolute;
            width: 100%;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            color: #fff;
            text-shadow: 0 0 2px rgb(0 0 0 / 30%), 1px 1px 1px rgb(0 0 0 / 20%);

            h5 {
                font-size: 1.625rem;
                font-weight: $font-weight-bold;
            }

            p {
                font-size: 1.125rem;
                margin-bottom: 0;
            }
        }

        &:hover {
            figure {
                img {
                    max-width: 100%;
                    transform: scale(1.1);
                }
            }
        }
    }

    .grid-item-1 {
        grid-area: a1tall;
    }

    .grid-item-2 {
        grid-area: a2;
    }

    .grid-item-3 {
        grid-area: a3;
    }

    .grid-item-4 {
        grid-area: a4tall;
    }

    .grid-item-5 {
        grid-area: a5;
    }

    .grid-item-6 {
        grid-area: a6;
    }

    .grid-item-7 {
        grid-area: a7;
    }

    display: grid;
    margin-bottom: 3rem;
    justify-content: center;
    gap: var(--bs-gutter-x);
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'a1tall'
        'a2'
        'a3'
        'a4tall'
        'a5'
        'a6'
        'a7';

    @include media-breakpoint-up(md) {
        grid-template-columns: 290px 290px;
        grid-template-rows: auto;
        grid-template-areas: 'a1tall a2'
            'a1tall a3'
            'a5 a4tall'
            'a6 a4tall'
            'a7 .';
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: 290px 290px 290px;
        grid-template-rows: auto;
        grid-template-areas: 'a1tall a2 a3'
            'a1tall a4tall a5'
            'a6 a4tall a7';
    }
}
