// Custom Colors
$primary-dark:   #b20062;
$secondary-dark: #0052a3;
$active:         #0ab21b;
$tertiary:       #febb02;
$highlight:      #faf7f3;
$gray-darker:    #666;
$gray-dark:      #aaa;
$gray-light:     #d8d8d8;
$gray-lighter:   #e9e9e9;

$color-booking:  $highlight; // Fastbooking

$card-focus-box-shadow: 0 0 0 0.25rem rgb(223 0 122 / 25%);
$accordion-focus-box-shadow: 0 1rem 1rem -1rem rgb(223 0 122 / 25%);


// Color system (6)
$white:          #fff;
$black:          #000;

$primary:        #df007a;
$secondary:      #06c;

//$enable-dark-mode: false;
//$color-mode-type: media-query;

// Body (433)
$body-color:     $black;
$body-bg:        $white;

// Links (449)
$link-decoration: none;

// Components (528)
$border-radius: 8px;
$border-color: $gray-dark;

// Typography (600)
$font-family-sans-serif: 'Roboto', sans-serif;
$font-weight-light:           300;
$font-weight-normal:          300;
$font-weight-medium:          500;
$font-weight-semibold:        500;
$font-weight-bold:            500;
$headings-font-weight:        300;

// Tables (726)
$table-th-font-weight: $font-weight-bold;
$table-striped-bg:     $gray-lighter;
$table-border-color:   $gray-light;

// Buttons + Forms (784)
$input-btn-padding-y:         0.375rem;
$input-btn-padding-x:         0.75rem;
$input-btn-padding-y-lg:      1rem;
$input-btn-padding-x-lg:      1.5rem;

// Buttons (813)
$btn-border-radius:           4px;
$btn-border-radius-sm:        4px;
$btn-border-radius-lg:        4px;

// Forms (866)
$form-label-font-weight:      400;
$input-border-color:          $gray-dark;
$input-border-radius:         4px;

// Pagination (1289)
$pagination-padding-y:              0;
$pagination-padding-x:              0;
$pagination-font-size:              .875rem;
$pagination-color:                  $body-color;
$pagination-border-radius:          50%;
$pagination-border-color:           $gray-light;
$pagination-active-bg:              $secondary;
$pagination-active-border-color:    $secondary;

// Cards (1339)
$card-spacer-y:         1.5rem;
$card-spacer-x:         1.5rem;
$card-border-width:     0;
$card-border-color:     $gray-light;
$card-cap-bg:           #fff;
$card-bg:               #fff;

// Accordion (1363)
$accordion-border-width:            0;
$accordion-body-padding-x:          0;
$accordion-body-padding-y:          0.25rem;
$accordion-button-padding-x:        0;
$accordion-button-active-bg:        $body-bg;
$accordion-button-active-color:     $body-color;
//$accordion-button-focus-box-shadow: $accordion-focus-box-shadow;
$accordion-button-focus-box-shadow: none;

// Breadcrumbs (1629)
$breadcrumb-divider-color:   $body-color;
$breadcrumb-active-color:    $body-color;
$breadcrumb-divider:         "\e911";
