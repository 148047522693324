body {
    position: relative;
}

header {
    position: relative;

    .header-container {
        position: relative;

        .header-toprow-container {
            background-color: $gray-lighter;

            .header-navbar {
                @extend .d-flex;
                @extend .flex-wrap;
                @extend .align-items-center;

                .navbar-logo {
                    img {
                        width: 180px;
                        height: auto;

                        @include media-breakpoint-up(md) {
                            width: 100%;
                        }
                    }
                }

                .navbar-main {
                    .navbar {
                        padding: 0;
                    }
                }

                .nav-main,
                .nav-icons {
                    .nav-item {
                        .nav-link {
                            font-size: 1rem;
                            font-weight: $font-weight-bold;
                            text-transform: uppercase;
                            color: $body-color;
                            height: 55px;
                            padding: 0 0.75rem;
                            display: flex;
                            align-items: center;
                            transition: color 0.3s ease-in;

                            @include media-breakpoint-up(md) {
                                height: 80px;
                            }
                        }
                    }
                }

                .mega-menu {
                    ul {
                        list-style: none;
                        padding-left: 0;
                    }
                }

                .navbar-meta {
                    text-align: end;
                    margin-left: auto;
                    display: flex;

                    .navbar-toggler {
                        color: $body-color;
                        padding-left: 0.75rem;
                    }

                    .dropdown-menu-service {
                        min-width: 200px;
                        padding-top: 0.75rem;
                        padding-bottom: 0.5rem;
                    }

                    .nav-lang {
                        margin: 0.5rem 1rem;
                        border-top: 1px solid $gray-lighter;
                        padding-top: 0.5rem;

                        a.nav-lang-item {
                            margin-right: 0.5rem;
                            text-transform: uppercase;
                            text-decoration: none;
                            color: $body-color;

                            &.active {
                                font-weight: 500;
                            }
                        }
                    }

                    .nav-contact {
                        margin: 0.5rem 1rem;
                        border-top: 1px solid $gray-lighter;
                        padding-top: 0.5rem;

                        .contact-block {
                            i {
                                margin-right: 0.5rem;
                            }

                            &.contact-block--hours {
                                font-size: 0.875rem;
                            }
                        }
                    }

                    .offcanvas {
                        text-align: start;

                        .menu-close {
                            float: right;
                            font-size: 1.5rem;
                            color: $gray-dark;

                            &:hover {
                                color: $body-color;
                            }
                        }

                        .nav-mobile-main {
                            .nav-link {
                                --#{$prefix}nav-link-padding-x: 0;

                                font-weight: 500;
                                color: $body-color;
                            }
                        }

                        .nav-mobile-service {
                            margin-top: 1rem;

                            .nav-link {
                                --#{$prefix}nav-link-padding-y: 0.25rem;
                                --#{$prefix}nav-link-padding-x: 0;

                                font-size: 0.875rem;
                                color: $body-color;
                            }
                        }

                        .nav-lang,
                        .nav-contact {
                            margin: 1rem 0 0;
                            padding: 1rem 0 0;
                        }
                    }
                }
            }
        }

        &.header-media {
            .header-toprow-container {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                z-index: 8;
                background-color: transparent;
                transition: all 0.3s ease-in;

                .header-navbar {
                    .nav-main,
                    .nav-icons {
                        .nav-item {
                            .nav-link {
                                color: #fff;
                            }
                        }
                    }

                    .navbar-meta {
                        .navbar-toggler {
                            color: #fff;
                        }
                    }
                }

                @include media-breakpoint-up(xl) {
                    &:hover,
                    &.active {
                        background-color: #fff;

                        .header-navbar {
                            .nav-main,
                            .nav-icons {
                                .nav-item {
                                    .nav-link {
                                        color: $body-color;
                                    }
                                }
                            }

                            .navbar-meta {
                                .navbar-toggler {
                                    color: $body-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.alert-bar {
    background-color: $secondary;
    padding: 0.25rem 0;
    color: #fff;

    a,
    a:active,a:hover,a:focus {
        color: #fff;
    }

    .container {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        height: 2rem;

        .message {
            text-align: center;

            a {
                text-decoration: underline;
            }
        }

        .icons {
            justify-items: end;
            text-align: right;
        }
    }
}
