@include color-mode(dark) {
    // _header.scss
    header {
        .header-container {
            .header-toprow-container {
                background-color: $body-bg-subtle-dark;

                .header-navbar {
                    .nav-main,
                    .nav-icons {
                        .nav-item {
                            .nav-link {
                                color: $white;
                            }
                        }
                    }
                }
            }

            &.header-media {
                .header-toprow-container {
                    background-color: rgb(0 0 0 / 0%);

                    &:hover,
                    &.active {
                        background-color: $body-bg-dark;

                        .header-navbar {
                            .nav-main,
                            .nav-icons {
                                .nav-item {
                                    .nav-link {
                                        color: $white;
                                    }
                                }
                            }

                            .navbar-meta {
                                .navbar-toggler {
                                    color: $white;
                                }

                                .nav-lang {
                                    a.nav-lang-item {
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // _page.scss
    .section-highlight {
        background-color: rgb(222 213 200 / 16%);
    }

    // _footer.scss
    footer {
        a,
        a.nav-link {
            color: $white;
        }
    }

    // _nav.scss
    ol.breadcrumb {
        --#{$prefix}breadcrumb-divider-color: #{$white};

        a {
            color: $white;
        }
    }

    // _content.scss
    .frame-type-menu_subpages,
    .frame-type-menu_pages,
    .frame-type-menu_section {
        &.frame-layout-0 {
            ul {
                li {
                    a {
                        color: $white;
                    }
                }
            }
        }
    }

    // _socialicons.scss
    .social-icons {
        .circle-wrap {
            border-color: $white;

            i {
                color: $white;
            }
        }
    }

    // _offers.scss
    .offer-list {
        a {
            color: $white;
        }

        .offer-item {
            .offer-data {
                .info {
                    color: $gray-light;
                }
            }
        }
    }

    .card {
        &.card-offer {
            --#{$prefix}card-bg: #{$body-bg-subtle-dark};
            --#{$prefix}card-cap-bg: #{$body-bg-subtle-dark};

            .card-body,
            .card-footer {
                border-color: #{$body-bg-subtle-dark};
            }
        }
    }

    // _fastbooking.scss
    .tx-knfastbooking {
        background-color: $body-bg-subtle-dark;

        .nav-tabs {
            .nav-link {
                color: $white;
            }
        }

        .form-control {
            color: $body-color;
            background-color: $white;
        }

        .form-floating {
            .mdb-select>label,
            >label {
                color: $body-color;
            }

            >.form-control:focus~label,
            >.form-control:not(:placeholder-shown)~label,
            >.form-control-plaintext~label,
            >.form-select~label {
                color: $body-color;

                &::after {
                    background-color: transparent;
                }
            }
        }

        .input-group-text {
            color: $body-bg-dark;
        }

        .dropdown-menu {
            --#{$prefix}dropdown-bg: #{$white};
            --#{$prefix}dropdown-color: #{$body-color};
        }
    }

    // _promobox.scss
    .promobox {
        .col-arrow {
            color: $body-color;
        }
    }

    // _pagination.scss
    .pagination {
        .page-link {
            #{$prefix}bs-pagination-color: #{$white};
        }
    }

    // mdb/*
    .datepicker-table {
        color: $body-color;
    }

    .select-dropdown-container {
        .form-check-input {
            --#{$prefix}form-check-bg: #{$white};

            &:checked {
                background-color: $primary;
                border-color: $primary;
            }
        }
    }

}
