ol.breadcrumb {
    margin-top: 4.5rem;

    .breadcrumb-item {
        &+.breadcrumb-item::before {
            // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
            font-family: iconkuoni;
            font-size: 0.75rem;
            padding-top: 3px;
        }

        &.active {
            a {
                font-weight: $font-weight-bold;
            }
        }
    }

    a {
        color: $body-color;
    }
}
