body {
    min-width: 320px;
}

.content-main {
    margin-top: 3rem;
}

.row {
    &.content-lead {
        margin-bottom: 3.5rem;

        .frame-type-menu_subpages {
            margin-bottom: 0;
        }
    }

    .col-sidebar {
        margin-top: 3rem;

        @include media-breakpoint-up(lg) {
            margin-top: 0;
        }
    }
}

.section-highlight {
    padding: 4.5rem 0;
    margin-bottom: 6rem;
    background-color: $highlight;
}
