.online-offers {
    margin-bottom: 4.5rem;

    a {
        &:focus-visible {
            display: block;
            border-radius: 8px;
            outline: 0;
            box-shadow: $card-focus-box-shadow;
        }
    }

    .no-offers-msg {
        font-style: italic;
    }
}

.header-container {
    .online-offers {
        margin-bottom: 0;
    }
}

.offer-list {
    a {
        color: $body-color;
    }

    .offer-item {
        flex-direction: column;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $gray-dark;
        padding: 1rem 0;

        &:hover {
            border-bottom-color: $primary;
        }

        .offer-data {
            align-self: flex-start;
            margin-bottom: 0.375rem;

            @include media-breakpoint-up(sm) {
                align-self: auto;
                margin-bottom: 0;
            }

            display: flex;
            flex-direction: column;
            font-size: 1.25rem;
            line-height: 1;
            padding-right: 1rem;

            .destination {
                margin-bottom: 0.375rem;
            }

            .info {
                font-size: 0.875rem;
                color: $gray-darker;
                padding: 0.25rem 0;

                @include media-breakpoint-up(sm) {
                    padding: 0;
                }
            }
        }

        .offer-price {
            align-self: flex-start;

            @include media-breakpoint-up(sm) {
                align-self: auto;
            }

            display: flex;
            flex-flow: row nowrap;
            flex-shrink: 0;
            align-items: center;
            font-size: 1.25rem;
            line-height: 1.25rem;
            color: $primary;

            i {
                font-size: 0.875rem;
                margin-left: 0.5rem;
            }
        }
    }
}

.card {
    &.card-offer {
        --#{$prefix}card-title-spacer-y: 0;

        .card-img-bg {
            position: relative;
            width: 100%;
            height: auto;

            &::before {
                display: block;
                width: 100%;
                padding-top: 66.6%;
                content: ' ';
            }

            .card-img-bg-content {
                position: absolute;
                inset: 0;
                background-position: center center;
                background-size: cover;
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
            }
        }

        .card-body,
        .card-footer {
            border-left: 1px solid $card-border-color;
            border-right: 1px solid $card-border-color;
        }

        .card-body {
            padding: var(--#{$prefix}card-spacer-y) var(--#{$prefix}card-spacer-x) 0;
        }

        .card-footer {
            border-bottom: 1px solid $card-border-color;
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }

        .card-destination {
            font-size: 0.875rem;
            margin-top: 0;
            margin-bottom: 0.125rem;
        }

        .card-category {
            margin-top: 0.125rem;
            margin-bottom: 0.5rem;
        }

        .card-recommendation {
            font-weight: $font-weight-bold;
            margin-bottom: 0.125rem;
        }

        .offer-desc {
            font-size: 0.875rem;
            margin-top: 0;
            margin-bottom: 0.125rem;
        }

        .offer-price-grp {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            .price {
                font-size: 1.5rem;
                color: $primary;
            }

            .price-icon {
                color: $primary;
                margin-left: 0.25rem;
            }
        }
    }

    &.card-morelink-magenta {
        background-color: $primary-dark;
        color: #fff;

        a {
            color: #fff;
        }

        .card-body {
            position: relative;
        }

        @include media-breakpoint-up(sm) {
            min-height: 200px;

            .card-text {
                margin-bottom: 3rem;
            }

            button {
                position: absolute;
                left: 1rem;
                bottom: 1rem;
            }
        }
    }

    &.card-morelink-image {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        .card-title,
        .card-text {
            color: #fff;
        }

        @include media-breakpoint-up(sm) {
            min-height: 350px;

            .card-overlay {
                position: absolute;
                inset: 0;
                background: linear-gradient(rgb(223 0 122 / 0%),rgb(223 0 122 / 35%));
                border-radius: $border-radius;
            }

            .card-body {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        @include media-breakpoint-down(sm) {
            background-image: none !important;
            background-color: $primary-dark;
        }
    }

    &.card-overlay {
        position: relative;

        a {
            display: block;
            color: #fff;
        }

        img {
            border-radius: $border-radius;
        }

        .card-body {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            color: #fff;
            transition: background-color 0.3s ease-in;
            background-color: rgb(0 0 0 / 48%);
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            padding: 0;

            h5 {
                margin-bottom: 0.5rem;
            }

            .inner {
                padding: 2.25rem;

                .destination {
                    font-size: 0.875rem;
                    margin-bottom: 0.5rem
                }

                .offer-footer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;

                    .offer-desc {
                        line-height: 1.5;
                        align-self: flex-start;
                    }

                    .price {
                        font-size: 1.75rem;
                        flex-shrink: 0;
                        align-self: flex-start;

                        i {
                            font-size: 1.25rem;
                        }
                    }

                    @include media-breakpoint-up(md) {
                        gap: 10px;

                        .price {
                            font-size: 1.5rem;
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        .price {
                            font-size: 1.75rem;
                        }
                    }
                }

                @include media-breakpoint-up(sm) {
                    padding: 1.5rem;

                    .offer-footer {
                        flex-direction: row;

                        .offer-desc {
                            align-self: auto;
                        }

                        .price {
                            align-self: auto;
                        }
                    }
                }
            }
        }

        &:hover {
            .card-body {
                background-color: rgb(223 0 122 / 78%);
            }
        }
    }
}

.card-overlay-grid-3col {
    .card.card-overlay {
        .card-body {
            .inner {
                padding: 1.5rem;

                .offer-footer {
                    .offer-desc {
                        font-size: 0.875rem;
                    }

                    .price {
                        font-size: 1.25rem;

                        i {
                            font-size: 0.875rem;
                        }
                    }
                }
            }
        }
    }
}

.slider-offer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    .container {
        padding-left: 0;
        padding-right: 0;
    }

    .offer-box {
        color: #fff;

        a {
            color: #fff;
        }

        background-color: $primary-dark;

        .top-content {
            padding: 0.75rem 1rem;
        }

        .offer-desc {
            font-size: 0.875rem;
        }

        h5 {
            font-size: 24px;
            margin: 0.5rem 0 1rem;
        }

        .price-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $primary;
            padding: 0.75rem 1rem;

            span.price {
                font-size: 20px;
            }

            span.icon {
                font-size: 16px;
            }
        }
    }

    @include media-breakpoint-between('sm', 'md') {
        .container {
            max-width: 100%;
        }
    }

    @include media-breakpoint-up('md') {
        top: 8rem;

        .container {
            padding-left: calc(var(--bs-gutter-x)*.5);
            padding-right: calc(var(--bs-gutter-x)*.5);
        }

        .offer-box {
            display: inline-block;
            min-width: 400px;
            border-radius: $border-radius;

            .destination {
                display: none;
            }

            .offer-desc {
                font-size: 1rem;
            }

            h5 {
                font-size: 32px;
            }

            .price-box {
                border-bottom-left-radius: $border-radius;
                border-bottom-right-radius: $border-radius;

                span.price {
                    font-size: 24px;
                }
            }
        }
    }

    @include media-breakpoint-up('lg') {
        top: 10rem;

        .offer-box {
            .top-content {
                padding: 1.5rem;
            }

            .destination {
                display: block;
            }

            .price-box {
                padding: 1rem 1.5rem;
            }
        }
    }
}

.more-offers {
    margin-top: 1.5rem;
}
